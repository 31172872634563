.outputCard {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  width: 350px;
  margin: 10px;
}

.outputsListContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.outputProfileContainer {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}

.finishOutputElementImage {
  width: 150px;
  height: 150px;
  border: 1px solid #222;
  border-radius: 15px;
  background-color: #f4f4f4;
  display: flex;
}

.aditionalServiceCard {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  width: 300px;
  margin: 10px;
}
