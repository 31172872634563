.inventoriesList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.inventoryCard {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  width: 250px;
  margin: 10px;
}

.inventoryElementCard {
  background-color: #fff;
  border-radius: 15px;
  /* padding: 20px; */
  text-align: center;
  width: 300px;
  min-height: 300px;
  margin: 10px;
}
