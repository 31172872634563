.keepmepointsCard {
  background-color: #fff;
  border-radius: 15px;
  /* padding: 20px; */
  text-align: center;
  width: 350px;
  margin: 10px;
}

.keepmepointsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.keepmepointsCategoryCard {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  min-width: 150px;
  margin: 10px;
}

.keepmepointsProfileContainer {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}

.keepmepointsContainer {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
}
