.loginContainer {
  display: flex;
  height: 100vh;
}

.loginFormCard {
  margin: auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  min-width: 350px;
  max-width: 450px;
}

.logoContainer {
  display: flex;
  padding: 10px;
}

.logo {
  width: 70%;
  margin: 0px auto;
}
