.brandKitContainer {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}

.brandProfileContainer {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}

.brandProfileContainer h1,
h2 {
  margin-bottom: 0;
}

.colorCircle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: #f4f4f4;
  display: flex;
  cursor: pointer;
}

.colorCircleShadow {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: #f4f4f4;
  display: flex;
}
.twitter-picker {
  box-shadow: none !important;
}

.colorInput {
  margin: 5px;
}

.colorInput .ant-input-number.ant-input-number-sm {
  width: 60px;
}

.brandColorsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.brandCard {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  width: 350px;
  margin: 10px;
}
.brandsListContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.brandProfileColorsCard {
  width: 280px;
  border: 1px dashed #222;
  display: flex;
  text-align: center;
  padding: 20px;
  margin: 5px;
  border-radius: 15px;
}

.brandProfileColorsContent h3,
p {
  margin-bottom: 0px;
}

.updateBrandProfileGeneralInfoForm .ant-form-item {
  margin-bottom: 0px;
}

.brandLogoPreviewImage {
  width: 300px;
  height: 300px;
  border: 1px dashed #222;
  border-radius: 15px;
  background-color: #f4f4f4;
  display: flex;
}
.brandFontPreviewImage {
  width:100%;
  height: 200px;
  max-width: 400px;
  border: 1px dashed #222;
  border-radius: 15px;
  background-color: #f4f4f4;
  display: flex;
}
