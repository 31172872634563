.complmenetProfileContainer {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}

.elementPreviewImage {
  width: 300px;
  height: 300px;
  border: 1px dashed #222;
  border-radius: 15px;
  background-color: #f4f4f4;
  display: flex;
}
.elementCardImage {
  width: 350px;
  height: 300px;
  border-radius: 15px 15px 0px 0px;
  /* background-color: #f4f4f4; */
  display: flex;
}

.complementsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.complementCard {
  background-color: #fff;
  border-radius: 15px;
  /* padding: 20px; */
  text-align: center;
  width: 350px;
  margin: 10px;
}

.categoryCard {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  min-width: 250px;
  margin: 10px;
}
.categoriesListContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
