.eventPreviewImage {
  width: 600px;
  height: 220px;
  border: 1px dashed #222;
  border-radius: 15px;
  background-color: #fff;
  display: flex;
}

.eventCard {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  width: 350px;
  margin: 10px;
}
