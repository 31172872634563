/* 
boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)"
Negro: #222222
Amarillo: #ffd700
Gris: #f3f2f1 
*/

/*General components*/
.ant-btn.ant-btn-primary {
  color: #222222;
  border-radius: 0;
}

.ant-table-pagination.ant-pagination {
  margin-right: 10px;
  margin-left: 10px;
}

.pageContainer {
  padding: 30px;
}

.pageTitle {
  color: #222222;
  text-align: center;
  margin-bottom: 0;
}

.underlineMetric {
  border-bottom: 2px solid #ffd700;
}

/*Pagination*/
.ant-pagination-item-active {
  background-color: #ffd700;
  color: #222222;
  border-radius: 30px;
}
.ant-pagination-item-active a {
  color: #222222;
}
