.seriesList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.serieCard {
  background-color: #fff;
  border-radius: 15px;
  /* padding: 20px; */
  text-align: center;
  width: 300px;
  min-height: 300px;
  margin: 10px;
}

.serieCardImage {
  width: 300px;
  height: 300px;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  /* background-color: #f4f4f4;*/
}

.seriePreviewImage {
  width: 300px;
  height: 300px;
  border: 1px dashed #222;
  border-radius: 15px;
  background-color: #fff;
  display: flex;
}

.serieProfileContainer {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}
