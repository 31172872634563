.usersCardsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.userCard {
  min-width: 250px;
  background-color: #fff;
  padding: 30px;
  margin: 20px;
  border-radius: 15px;
}

.editUserFormContainer .ant-form-item {
  margin-bottom: 0;
}
