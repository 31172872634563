.companyCard {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  min-width: 250px;
  margin: 10px;
}

.companyCard h2 {
  margin-bottom: 0px;
}

.companiesListContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
